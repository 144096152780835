"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingWizardJsPaymentModalHolder = void 0;
const styled_components_1 = require("styled-components");
const ui_1 = require("client/ui");
exports.BookingWizardJsPaymentModalHolder = (0, styled_components_1.styled)(ui_1.BaseModal) `
  & .wpwl-form-card {
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    box-shadow: none;

    ${({ theme: { font, lineHeight } }) => (0, styled_components_1.css) `
      font-family: ${font.name};
      font-size: ${font.size.base};
      line-height: ${lineHeight.base};
    `}
  }

  ${({ theme: { spacing } }) => (0, styled_components_1.css) `
    & .wpwl-group-submit {
      margin-top: ${spacing[4]};
      margin-bottom: ${spacing[6]};
    }
  `}
`;
